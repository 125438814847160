import { Box, ChakraProvider } from '@chakra-ui/react';
import Alerts from 'components/Alerts';
import Layout from 'components/Layout';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import theme from 'theme';
import { WagmiConfig } from 'wagmi';

import { version } from './const';
import { AppState, StoreContext } from './store';
import { wagmiClient } from './utils/connector';

const Home = lazy(() => import('pages/Home'));
const Instructions = lazy(() => import('pages/Instructions'));
const Earn = lazy(() => import('pages/Earn'));

const appState: AppState = new AppState();

const App = () => {
  // eslint-disable-next-line no-console
  console.log('version: ', version);

  return (
    <StoreContext.Provider value={appState}>
      <ChakraProvider theme={theme}>
        <WagmiConfig client={wagmiClient}>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<></>}>
                      <Home />
                    </Suspense>
                  }
                />

                <Route
                  path="/instructions"
                  element={
                    <Suspense fallback={<></>}>
                      <Instructions />
                    </Suspense>
                  }
                />

                <Route
                  path="/earn"
                  element={
                    <Suspense fallback={<></>}>
                      <Earn />
                    </Suspense>
                  }
                />

                <Route path="*" element={<Box>Not Found</Box>} />
              </Routes>

              <Alerts />
            </Layout>
          </BrowserRouter>
        </WagmiConfig>
      </ChakraProvider>
    </StoreContext.Provider>
  );
};

export default App;
