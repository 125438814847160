export const Button = {
  variants: {
    default: {
      bg: 'yellow.400',
      color: 'blue.90',
      cursor: 'pointer',
      boxShadow:
        'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset'
    }
  }
};
