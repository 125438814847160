/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx';

import AlertsStates from './alertsState';

export default class AppState {
  constructor() {
    this._alerts = new AlertsStates({ appState: this });

    makeAutoObservable(this);
  }

  private _alerts;

  get alerts(): AlertsStates {
    return this._alerts;
  }
}
