/* eslint-disable max-classes-per-file */
// @flow
import React from 'react';

import AppState from './appState';

// prettier-ignore
export const StoreContext = React.createContext<AppState | null>(null)

export const useStore = (): AppState => {
  const store = React.useContext(StoreContext);

  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.');
  }

  return store;
};
