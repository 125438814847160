import { Flex } from '@chakra-ui/react';
import React, { lazy, Suspense } from 'react';

const Header = lazy(() => import('components/Header'));

interface Layout {
  children: React.ReactNode;
}

const Layout = ({ children }: Layout) => {
  return (
    <Flex height="100vh">
      <Flex direction={'column'} width={'full'} ml="5" mr="5" mt="5">
        <Flex width={'full'} mb="10">
          <Suspense fallback={<></>}>
            <Header />
          </Suspense>
        </Flex>

        <Flex width={'full'}>{children}</Flex>

        <Flex width={'full'} mt={'23px'}>
          <div />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Layout;
