export const Table = {
  variants: {
    default: {
      thead: {
        background: 'grey.regular',
        th: { fontSize: 'xs', textTransform: 'unset' },
        'th:first-of-type': {
          borderRadius: '5px 0 0 5px'
        },
        'th:last-of-type': {
          borderRadius: '0 5px 5px 0'
        }
      },
      tbody: {
        td: {
          fontSize: 'xxs'
        }
      }
    }
  }
};
