import { providers } from 'ethers';
import { Chain, chain, configureChains, createClient } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

export const connectorsMeta = [
  { name: 'MetaMask', icon: 'metamask.svg' },
  { name: 'WalletConnect', icon: 'wallet-connect.svg' },
  { name: 'Coinbase Wallet', icon: 'coinbase.svg' }
];

const alchemyId = process.env.REACT_APP_ALCHEMY_KEY;

const mumbai = {
  id: chain.polygonMumbai.id,
  name: chain.polygonMumbai.name,
  nativeCurrency: {
    name: 'MATIC Token',
    symbol: 'MATIC',
    decimals: 18
  },
  rpcUrls: {
    default:
      'https://polygon-mumbai.g.alchemy.com/v2/oQ23n32KXKvkeCliOZwtkZfm_-pAMSny'
  },

  blockExplorers: {
    default: {
      name: 'Polygonscan',
      url: 'https://mumbai.polygonscan.com'
    }
  },
  network: chain.polygonMumbai.network,
  testnet: true
} as Chain;
const customChains = [mumbai];

const { chains } = configureChains(customChains, [
  alchemyProvider({ apiKey: alchemyId }),
  publicProvider()
]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      options: {
        shimDisconnect: true
      }
    })
  ],
  provider: (config) => {
    const chainId = config?.chainId ?? chain.polygonMumbai.id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (window.ethereum && window.ethereum.selectedAddress!) {
      return new providers.Web3Provider(window.ethereum as any, chainId);
    }

    return new providers.AlchemyProvider(chainId, alchemyId);
  },
  webSocketProvider: (config) => {
    const chainId = config?.chainId ?? chain.polygonMumbai.id;

    return new providers.AlchemyWebSocketProvider(chainId, alchemyId);
  }
});
