/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx';

/* eslint-disable no-underscore-dangle */
import { ModelsDeps } from '../types';
import { AlertError, AlertNotice, Alerts } from './../types';

export default class AlertsState {
  constructor(deps: ModelsDeps) {
    const { appState } = deps;

    this._appState = appState;

    makeAutoObservable(this);
  }

  private _appState: ModelsDeps['appState'];

  private _alerts: Alerts = { list: [] };

  addError = (alert: Pick<AlertError, 'description'>): void => {
    const error: AlertError = {
      title: 'Error',
      description: alert.description,
      status: 'error'
    };

    this._alerts.list.push(error);
  };

  addWarning = (alert: Pick<AlertNotice, 'description'>): void => {
    const error: AlertNotice = {
      title: 'Warning',
      description: alert.description,
      status: 'warning'
    };

    this._alerts.list.push(error);
  };

  addNotice = (alert: Pick<AlertNotice, 'description' | 'status'>): void => {
    const error: AlertNotice = {
      title: 'Info',
      description: alert.description,
      status: alert.status
    };

    this._alerts.list.push(error);
  };

  get data(): Alerts {
    return this._alerts;
  }
}
