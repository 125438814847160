/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from 'store';

const Alerts: React.FC<unknown> = () => {
  const toast = useToast();

  const store = useStore();

  const { alerts } = store;

  useEffect(() => {
    if (alerts.data.list.length !== 0) {
      const { title, description, status } =
        alerts.data.list[alerts.data.list.length - 1];

      toast({
        title,
        description,
        status,
        duration: status === 'error' ? 10000 : null,
        isClosable: true,
        position: 'bottom-right'
      });
    }
  }, [alerts.data.list.length, toast]);

  return <></>;
};

export default observer(Alerts);
