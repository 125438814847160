export const version = 'v0.1.3';

export const SLOT_MACHINE_ADDRESS = process.env
  .REACT_APP_SLOT_MACHINE_ADDRESS as string;

export const TREASURY_ADDRESS = process.env
  .REACT_APP_TREASURY_ADDRESS as string;

const USDT = {
  kovan: '0x13512979ADE267AB5100878E2e0f485B568328a4',
  polygon: process.env.REACT_APP_USDT_ADDRESS_MUMBAI as string // Slotmachine mockUSDT contract
};

export const UNSTP_TOKEN_ADDRESS = process.env
  .REACT_APP_UNSTP_TOKEN_ADDRESS as string;

export const STAKING_ADDRESS = process.env.REACT_APP_STAKING_ADDRESS as string;

export const USDT_ADDRESS = USDT.polygon;
